import React, { useEffect, useState } from "react";
import { Col, Form, InputNumber, Row, Slider } from "antd";
import { notificationPlacements, useNotification } from "contexts/NotificationContext";
import { useDispatch, useSelector } from "state/store";
import { chatSlice } from "state/slices";

const defaultFormData = {
  outputTokenCount: 100,
  formal: 0,
  pessimist: 0,
  friendly: 0,
};

const Settings = () => {
  const dispatch = useDispatch();

  const { toneSettings } = useSelector((state) => state.chat);

  const [form] = Form.useForm();
  const notificationApi = useNotification();

  const [formData, setFormData] = useState(defaultFormData);

  useEffect(() => {
    const storedSettings = JSON.parse(localStorage.getItem("tone_settings"));
    if (storedSettings) {
      setFormData({ ...formData, ...storedSettings });
      form.setFieldsValue({ ...formData, ...storedSettings });
      dispatch(chatSlice.actions.updateToneSettings(storedSettings));
    } else if (toneSettings) {
      setFormData({ ...formData, ...toneSettings });
      form.setFieldsValue({ ...formData, ...toneSettings });
    }
  }, []);

  function handleOnChangeSlider(id, value) {
    setFormData({ ...formData, [id]: value });
    form.setFields([
      {
        name: id,
        errors: [],
        value,
      },
    ]);
    dispatch(chatSlice.actions.updateToneSettings({ [id]: value }));
    notificationApi["success"]("", "Updated Tone Settings", notificationPlacements.bottomRight, {
      duration: 1,
    });
  }

  function handleChangeSlider(id, value) {
    setFormData({ ...formData, [id]: value });
  }

  // function handleInputChange(e) {
  //   const { value, id } = e.target;
  //   setFormData({ ...formData, [id]: value });
  //   form.setFields([
  //     {
  //       name: id,
  //       errors: [],
  //       value,
  //     },
  //   ]);
  //   dispatch(chatSlice.actions.updateToneSettings({ [id]: value }));
  // }

  function handleInputNumberChange(id, value) {
    setFormData({ ...formData, [id]: value });
    form.setFields([
      {
        name: id,
        errors: [],
        value,
      },
    ]);
    dispatch(chatSlice.actions.updateToneSettings({ [id]: value }));
  }

  return (
    <div style={{ padding: 20, height: "100%" }}>
      <Form
        className="tone_settings__form"
        layout="vertical"
        form={form}
        autoComplete="off"
        initialValues={formData}
        id="tone_settings__form"
      >
        <Row className="form_row" gutter={{ lg: 16, xs: 0, sm: 0, md: 16 }}>
          <Col xs={24} sm={24} md={12} xl={8} className="form_item__col">
            <Form.Item wrapperCol={24}>
              <div className="slider__container">
                <div>
                  <Slider
                    id="formal"
                    onChangeComplete={(value) => handleOnChangeSlider("formal", value)}
                    onChange={(value) => handleChangeSlider("formal", value)}
                    value={formData.formal}
                  />
                </div>
                <div className="options">
                  <span>Informal</span>
                  <span>Formal</span>
                </div>
              </div>
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} xl={8} className="form_item__col">
            <Form.Item wrapperCol={24}>
              <div className="slider__container">
                <div>
                  <Slider
                    id="friendly"
                    onChangeComplete={(value) => handleOnChangeSlider("friendly", value)}
                    onChange={(value) => handleChangeSlider("friendly", value)}
                    value={formData.friendly}
                  />
                </div>
                <div className="options">
                  <span>Friendly</span>
                  <span>Assertive</span>
                </div>
              </div>
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} xl={8} className="form_item__col">
            <Form.Item wrapperCol={24}>
              <div className="slider__container">
                <div>
                  <Slider
                    id="pessimist"
                    onChangeComplete={(value) => handleOnChangeSlider("pessimist", value)}
                    onChange={(value) => handleChangeSlider("pessimist", value)}
                    value={formData.pessimist}
                  />
                </div>
                <div className="options">
                  <span>Pessmist</span>
                  <span>Optimist</span>
                </div>
              </div>
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} xl={8} className="form_item__col">
            <Form.Item
              wrapperCol={24}
              label="Output Length"
              name="outputTokenCount"
              rules={[
                {
                  type: "number",
                  min: 100,
                  max: 1000,
                  message: "Output Token Count must be a number between 100 and 1000",
                },
              ]}
            >
              <InputNumber
                id="outputTokenCount"
                placeholder="Output Length"
                value={formData.outputTokenCount}
                onChange={(value) => handleInputNumberChange("outputTokenCount", value)}
                onBlur={() => {
                  const errors = form.getFieldError("outputTokenCount");
                  if (errors && errors.length === 0) {
                    notificationApi["success"](
                      "",
                      "Updated Tone Settings",
                      notificationPlacements.bottomRight,
                    );
                  }
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Settings;

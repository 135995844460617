import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { motion } from "framer-motion";
import { Button, Divider, Drawer, Dropdown, Input } from "antd";

import {
  MoreOutlined,
  DeleteFilled,
  LeftOutlined,
  PlusCircleOutlined,
  SearchOutlined,
  StarOutlined,
  SmileOutlined,
  HeartOutlined,
} from "@ant-design/icons";

import { useDispatch, useSelector } from "state/store";
import { chatSlice } from "state/slices";

function History(props) {
  const {
    chatHistory,
    handleClickNewChat,
    loadCurrentChat,
    handleDeleteChat,
    handleCategorizeChat,
  } = props;

  const dispatch = useDispatch();

  const { historyDrawerOpen } = useSelector((state) => state.chat);

  const [filteredItems, setFilteredItems] = useState(null);

  function handleOnSearch(e) {
    const { value } = e.target;
    const filteredItems = chatHistory.filter(
      (x) => x.label.toLowerCase().indexOf(value.toLowerCase()) !== -1,
    );
    setFilteredItems(filteredItems || []);
  }

  let messageHistory = filteredItems ? filteredItems : chatHistory;

  return (
    <>
      <Drawer
        placement="left"
        closable={false}
        onClose={() => {
          dispatch(chatSlice.actions.toggleHistoryDrawer(false));
        }}
        open={historyDrawerOpen}
        className="history_drawer"
      >
        <div className="header">
          <div className="icon_btn">
            <LeftOutlined
              style={{ fontSize: 20 }}
              onClick={() => {
                dispatch(chatSlice.actions.toggleHistoryDrawer(false));
              }}
            />
          </div>
          <span className="title">History</span>
        </div>
        <Divider style={{ borderColor: "#bdbebf" }} />
        <Button
          className="btn"
          type="primary"
          shape="round"
          icon={<PlusCircleOutlined />}
          size="large"
          style={{ width: "100%" }}
          onClick={handleClickNewChat}
        >
          Start New Chat
        </Button>
        <Input
          size="large"
          placeholder="Search for empathy"
          prefix={<SearchOutlined />}
          className="search_input"
          onChange={handleOnSearch}
        />
        <Divider style={{ borderColor: "#bdbebf" }} />
        {messageHistory.map((val) => (
          <motion.div
            key={val.id}
            onClick={() => {
              loadCurrentChat(val.id);
            }}
          >
            <div className="chat_history__container">
              <span className="label">{val.label || ""}</span>
              <div className="more">
                {val.category ? (
                  <span
                    style={{
                      backgroundColor:
                        val.category === "Friend"
                          ? "lightgreen"
                          : val.category === "Work"
                            ? "cyan"
                            : val.category === "Relationship"
                              ? "pink"
                              : null,
                      borderRadius: "25px",
                      padding: "0px 5px",
                    }}
                  >
                    {val.category}
                  </span>
                ) : null}
                {val.createdAt ? <span>{moment(val.createdAt).format("hh:mm")}</span> : <></>}
                <Dropdown
                  menu={{}}
                  placement="bottomLeft"
                  trigger={["click"]}
                  dropdownRender={() => (
                    <div className="dropdown_menu">
                      <div className="item" onClick={() => handleDeleteChat(val.id)}>
                        <DeleteFilled /> <span>Delete</span>
                      </div>
                      <div className="item" onClick={() => handleCategorizeChat(val.id, "Friend")}>
                        <SmileOutlined /> <span>Friend</span>
                      </div>
                      <div className="item" onClick={() => handleCategorizeChat(val.id, "Work")}>
                        <StarOutlined /> <span>Work</span>
                      </div>
                      <div
                        className="item"
                        onClick={() => handleCategorizeChat(val.id, "Relationship")}
                      >
                        <HeartOutlined /> <span>Relationship</span>
                      </div>
                    </div>
                  )}
                >
                  <MoreOutlined />
                </Dropdown>
              </div>
            </div>
            <Divider className="custom_divider" />
          </motion.div>
        ))}
      </Drawer>
    </>
  );
}

History.propTypes = {
  chatHistory: PropTypes.array,
  handleClickNewChat: PropTypes.func,
  handleDeleteChat: PropTypes.func,
  loadCurrentChat: PropTypes.func,
  handleCategorizeChat: PropTypes.func,
};

export default History;

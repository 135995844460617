import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";

import { truncateText } from "utils/text";
import { sendMessage } from "services/openai";
import { useDispatch, useSelector } from "state/store";
import { History } from "containers/Chat";
import { chatSlice } from "state/slices";

import ChatView from "./chat.view";
import { generatePrompt } from "./utils";

/**
 * The main Chat component for handling user interactions and chat history.
 * @component
 */
const Chat = () => {
  const { toneSettings } = useSelector((state) => state.chat);

  const dispatch = useDispatch();

  const [chatHistory, setChatHistory] = useState([]);
  const [messages, setMessages] = useState([]);
  const [currentChat, setCurrentChat] = useState({});

  const [selectedTab, setSelectedTab] = useState("chat");

  // Effect hook to initialize the current chat ID on component mount
  useEffect(() => {
    setCurrentChat({ id: uuid(), createdAt: new Date() });
  }, []);

  // Effect hook to load chat history from local storage on component mount
  useEffect(() => {
    const storedChatHistory = JSON.parse(localStorage.getItem("chat_history"));
    if (storedChatHistory) {
      setChatHistory(storedChatHistory);
    }
  }, []);

  // Effect hook to save chat history to local storage whenever it changes
  useEffect(() => {
    if (chatHistory.length > 0) {
      localStorage.setItem("chat_history", JSON.stringify(chatHistory));
    }
  }, [chatHistory]);

  // Effect hook to update the messages in the current chat
  useEffect(() => {
    if (messages && currentChat.id) {
      setChatHistory((prevState) => {
        const index = prevState.findIndex((chat) => chat.id === currentChat.id);
        if (index !== -1) {
          prevState[index] = { ...prevState[index], messages };
        }
        return [...prevState];
      });
    }
  }, [messages]);

  // Function to handle sending messages
  async function handleSendMessage(message) {
    try {
      setMessages((prevState) => [...prevState, { role: "user", createdAt: new Date(), message }]);

      // Create a new chat if it doesn't exist in chatHistory
      if (messages.length === 0) {
        const currentChatExists = chatHistory.find((x) => x.id === currentChat.id);
        if (!currentChatExists) {
          const newChat = {
            ...currentChat,
            label: truncateText(message, 25),
            messages: [{ role: "user", createdAt: new Date(), message }],
            createdAt: new Date(),
          };
          setChatHistory((prevState) => [...prevState, newChat]);
        }
      }

      const tonePrompt = generatePrompt(toneSettings, message);

      const prompt = {
        role: "user",
        content: tonePrompt,
      };

      const mappedMessages = messages.map((val) => ({
        role: "user",
        content: val.message,
      }));

      const res = await sendMessage(prompt, mappedMessages);
      if (res) {
        setMessages((messages) => [
          ...messages,
          {
            role: "assistant",
            message: res,
          },
        ]);
      }
    } catch (e) {
      console.error(e);
    }
  }

  // Function to load a specific chat by its ID
  function loadCurrentChat(id) {
    if (currentChat.id === id) {
      return;
    }
    const relevantChat = chatHistory.find((x) => x.id === id);
    if (relevantChat) {
      dispatch(chatSlice.actions.toggleHistoryDrawer(false));
      setMessages(relevantChat.messages);
      setCurrentChat(relevantChat);
    }
  }

  function handleNewChat() {
    dispatch(chatSlice.actions.toggleHistoryDrawer(false));

    if (messages.length === 0) {
      return;
    }

    const isChatAlreadyExists = chatHistory.find((x) => x.id === currentChat.id);

    const newChat = {
      ...currentChat,
      label: truncateText(messages[0].message, 25),
      messages,
      createdAt: new Date(),
    };

    if (!isChatAlreadyExists) {
      setChatHistory((prevState) => [...prevState, newChat]);
    } else {
      // Update the existing chat in place
      setChatHistory((prevState) => {
        const index = prevState.findIndex((chat) => chat.id === currentChat.id);
        if (index !== -1) {
          prevState[index] = newChat;
        }
        return [...prevState];
      });
    }

    setMessages([]);
    setCurrentChat({ id: uuid() });
  }

  // Function to handle deleting a chat by its ID
  function handleDeleteChat(id) {
    const filteredChatHistory = chatHistory.filter((chat) => chat.id !== id);
    setChatHistory(filteredChatHistory);
    if (currentChat?.id === id) {
      setCurrentChat({});
      setMessages([]);
    }
    localStorage.setItem("chat_history", JSON.stringify(filteredChatHistory));
  }

  function handleCategorizeChat(id, category) {
    const newChatHistory = chatHistory.map((chat) => {
      if (chat?.id === id) {
        chat.category = category;
      }
      return chat;
    });
    setChatHistory(newChatHistory);
    localStorage.setItem("chat_history", JSON.stringify(newChatHistory));
  }

  return (
    <>
      <ChatView
        setSelectedTab={setSelectedTab}
        selectedTab={selectedTab}
        messages={messages}
        handleSendMessage={handleSendMessage}
      />
      <History
        chatHistory={chatHistory}
        handleClickNewChat={handleNewChat}
        loadCurrentChat={loadCurrentChat}
        handleDeleteChat={handleDeleteChat}
        handleCategorizeChat={handleCategorizeChat}
      />
    </>
  );
};

export default Chat;

import React, { useState } from "react";
import { Button, Form, Input } from "antd";
import { SendOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";

const MessageInput = (props) => {
  const { handleSendMessage } = props;

  const [message, setMessage] = useState("");

  const [form] = Form.useForm();

  function handleInputChange(e) {
    const { value } = e.target;
    setMessage(value);
    form.setFields([
      {
        name: "message",
        errors: [],
        value,
      },
    ]);
  }

  function submitForm() {
    handleSendMessage(message);
    setMessage("");
    form.resetFields();
  }

  return (
    <>
      <div>
        <Form
          name="chatInput"
          className="input_form"
          onFinish={submitForm}
          autoComplete="off"
          form={form}
        >
          <Form.Item
            name="message"
            rules={[
              {
                required: true,
                message: "Message is required",
              },
            ]}
            style={{ width: "100%" }}
            validateTrigger="onSubmit"
            noStyle
          >
            {/* <TextArea
              rows={1}
              onChange={handleInputChange}
              value={message}
              onPressEnter={form.submit}
              placeholder="Tell us to generate empathy"
              className="chat_input"
            /> */}
            <Input
              id="message"
              placeholder="Tell us to generate empathy"
              value={message}
              onChange={handleInputChange}
              onPressEnter={form.submit}
              className="chat_input"
            />
          </Form.Item>

          <Button
            type="default"
            shape="round"
            size="large"
            htmlType="submit"
            className="btn send_btn"
            id="send_message__btn"
          >
            <SendOutlined style={{ color: "#FFF" }} />
          </Button>
        </Form>
      </div>
    </>
  );
};

MessageInput.propTypes = {
  handleSendMessage: PropTypes.func,
};

export default MessageInput;

"use client";

/* Core */
import { Provider } from "react-redux";

/* Instruments */
import { reduxStore } from "./store";

// eslint-disable-next-line react/prop-types
const StoreProvider = ({ children }) => {
  return <Provider store={reduxStore}>{children}</Provider>;
};

export default StoreProvider;

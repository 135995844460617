export async function sendMessage(prompt, messages) {
  try {
    const response = await fetch("https://api.openai.com/v1/chat/completions", {
      method: "POST",
      headers: {
        // eslint-disable-next-line no-undef
        Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        model: "gpt-3.5-turbo-1106",
        messages: [...messages, prompt],
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();

    if (data?.choices) {
      const res = data.choices[0].message.content;
      return res;
    }
  } catch (error) {
    console.error("Error while sending message:", error);
    throw error;
  }
}

import React from "react";
import PropTypes from "prop-types";

function Messages(props) {
  const { messages } = props;

  return (
    <div className="messages_container" style={{ height: "100%" }}>
      {messages?.map((val, index) => (
        <div key={index} className={`message ${val.role === "user" ? "user" : "other"}`}>
          {val?.message}
        </div>
      ))}
    </div>
  );
}

Messages.propTypes = {
  messages: PropTypes.array,
};

export default Messages;

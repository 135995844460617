import React from "react";
import PropTypes from "prop-types";
import { Radio } from "antd";
import { MenuOutlined } from "@ant-design/icons";

import { useDispatch } from "state/store";
import { chatSlice } from "state/slices";

function Tabs(props) {
  const { setSelectedTab, selectedTab } = props;

  const dispatch = useDispatch();

  function handleClickMenu() {
    dispatch(chatSlice.actions.toggleHistoryDrawer(true));
  }

  return (
    <>
      <div className="menu_item__web" onClick={handleClickMenu}>
        <MenuOutlined style={{ fontSize: 16 }} />
      </div>
      <div className="chat_tabs custom_tab">
        <div className="menu_item__tab" onClick={handleClickMenu}>
          <MenuOutlined style={{ fontSize: 16 }} />
        </div>
        <Radio.Group value={selectedTab} onChange={(e) => setSelectedTab(e.target.value)}>
          <Radio.Button value="chat" className="tab">
            Generate Empathy
          </Radio.Button>
          <Radio.Button value="setting" className="tab">
            Tone Settings
          </Radio.Button>
        </Radio.Group>
      </div>
    </>
  );
}

Tabs.propTypes = {
  setSelectedTab: PropTypes.func,
  selectedTab: PropTypes.string,
};

export default Tabs;

/* Core */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  theme: "light",
  menuCollapsed: false,
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    toggleMenuCollapsed: (state, action) => {
      state.menuCollapsed = action.payload;
    },
  },
});

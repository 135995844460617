import React from "react";
import PropTypes from "prop-types";
import { Layout, theme } from "antd";

import { MessageInput, Messages, Settings, Tabs } from "containers/Chat";

const { Content } = Layout;

function ChatView(props) {
  const { setSelectedTab, selectedTab, messages, handleSendMessage } = props;

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Layout
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Content className="chat_view">
          <div className="chat">
            <Tabs setSelectedTab={setSelectedTab} selectedTab={selectedTab} />
            {selectedTab === "chat" && <Messages messages={messages} />}
            {selectedTab === "setting" && <Settings />}
          </div>
          {selectedTab === "chat" && (
            <div
              style={{
                padding: 24,
                textAlign: "center",
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
              }}
            >
              <MessageInput handleSendMessage={handleSendMessage} />
            </div>
          )}
        </Content>
        {/* <Footer style={{ textAlign: "center" }}>
          Open Heart ©{new Date().getFullYear()}
        </Footer> */}
      </Layout>
    </Layout>
  );
}

ChatView.propTypes = {
  setSelectedTab: PropTypes.func.isRequired,
  selectedTab: PropTypes.string.isRequired,
  messages: PropTypes.array.isRequired,
  handleSendMessage: PropTypes.func.isRequired,
};

export default ChatView;

export function generatePrompt(toneSettings, message) {
  const { formal, friendly, pessimist, outputTokenCount } = toneSettings;

  // Simplifying the tone descriptions
  let toneDescription = "Please write a response that is ";

  if (formal > 50) toneDescription += "more formal, ";
  else toneDescription += "more informal, ";

  if (friendly > 50) toneDescription += "friendly, ";
  else toneDescription += "neutral in tone, ";

  if (pessimist > 50) toneDescription += "with a cautious or realistic outlook, ";
  else toneDescription += "optimistic, ";

  toneDescription += `aiming for around ${outputTokenCount} tokens in length.`;

  const tonePrompt = `
        Given the user's message: "${message}"
        ${toneDescription}
        Please strictly follow these tone settings and only return the response.
    `;

  return tonePrompt;
}

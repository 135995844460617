import React, { createContext, useContext } from "react";
import { notification } from "antd";
import PropTypes from "prop-types";

// declare const NotificationPlacements: readonly ["top", "topLeft", "topRight", "bottom", "bottomLeft", "bottomRight"];

const NotificationContext = createContext(null);

export const notificationPlacements = {
  top: "top",
  topLeft: "topLeft",
  topRight: "topRight",
  bottom: "bottom",
  bottomLeft: "bottomLeft",
  bottomRight: "bottomRight",
};

const NotificationProvider = ({ children }) => {
  const api = {
    success: (title, description, placement = "topRight", others = {}) => {
      notification.success({
        message: title,
        description,
        placement,
        ...others,
      });
    },
    info: (title, description, placement = "topRight", others = {}) => {
      notification.info({ message: title, description, placement, others });
    },
    warning: (title, description, placement = "topRight", others = {}) => {
      notification.warning({
        message: title,
        description,
        placement,
        ...others,
      });
    },
    error: (title, description, placement = "topRight", others = {}) => {
      notification.error({ message: title, description, placement, ...others });
    },
  };

  return <NotificationContext.Provider value={api}>{children}</NotificationContext.Provider>;
};

export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error("useNotification must be used within a NotificationProvider");
  }
  return context;
};

NotificationProvider.propTypes = {
  children: PropTypes.any,
};

export default NotificationProvider;

/* Core */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  historyDrawerOpen: false,
  toneSettings: {
    outputTokenCount: 100,
    formal: 0,
    pessimist: 0,
    friendly: 0,
  },
};

export const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    toggleHistoryDrawer: (state, action) => {
      state.historyDrawerOpen = action.payload;
    },
    updateToneSettings: (state, action) => {
      const updatedState = { ...state.toneSettings, ...action.payload };
      state.toneSettings = updatedState;
      localStorage.setItem("tone_settings", JSON.stringify(updatedState));
    },
  },
});
